import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { ReservationService } from '@app/reservation/reservation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Room } from '../reservation-settings.model';

@Component({
  selector: 'app-add-reservation-addon-template',
  templateUrl: './add-reservation-addon-template.component.html',
  styleUrls: ['./add-reservation-addon-template.component.scss']
})
export class AddReservationAddOnTemplate implements OnInit, OnDestroy {
  @Input() public editReservationAddOnTemplate: any; // only if editing
  @Input() public clientData: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  reservationAddOnTemplateFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;
  imageUrl: string | ArrayBuffer;
  fileName: string;
  photoSizeError: string = 'Photo must be at least 800px wide and 200px high';
  photoFormatError: string = 'Unsupported file format. Only jpg, gif and png';
  allPrices: FormArray;
  rooms: Array<Room>;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.reservationAddOnTemplateFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
      price: [0, Validators.required],
      allPrices: this.formBuilder.array([]),
      description: [''],
      file: [''],
      deletePhoto: [false],
      priceOption: ['0'],
      rooms: [[]]
    });

    this.getAllRooms();

    if (this.editReservationAddOnTemplate) {
      this.reservationAddOnTemplateFormGroup.addControl(
        'id',
        new FormControl(this.editReservationAddOnTemplate.id, Validators.required)
      );
      this.reservationAddOnTemplateFormGroup.get('title').setValue(this.editReservationAddOnTemplate.title);
      // this.reservationAddOnTemplateFormGroup.get('price').setValue(this.editReservationAddOnTemplate.price);
      this.reservationAddOnTemplateFormGroup
        .get('description')
        .setValue(this.editReservationAddOnTemplate.description ? this.editReservationAddOnTemplate.description : '');
      if (this.editReservationAddOnTemplate.image) {
        this.fileName = this.editReservationAddOnTemplate.image;
        this.imageUrl = `https://cdn.gastroguide.de/${this.editReservationAddOnTemplate.image}`;
      }
      // Max guests per units
      const allPriceUnits: any = [];
      if (
        this.editReservationAddOnTemplate.addOnsSettings &&
        this.editReservationAddOnTemplate.addOnsSettings.length > 0
      ) {
        this.editReservationAddOnTemplate.addOnsSettings.forEach((st: any) => {
          st.price = st.price.replace('.', ',');
          allPriceUnits.push(this.formBuilder.group(st));
        });
        this.reservationAddOnTemplateFormGroup.setControl('allPrices', this.formBuilder.array(allPriceUnits));
      }
      this.reservationAddOnTemplateFormGroup
        .get('priceOption')
        .setValue(this.editReservationAddOnTemplate.priceOption.toString());
      console.log('hello', this.editReservationAddOnTemplate.addOnsRelation, this.editReservationAddOnTemplate);
      if (this.editReservationAddOnTemplate.addOnsRelation && this.editReservationAddOnTemplate.addOnsRelation.rooms) {
        this.reservationAddOnTemplateFormGroup
          .get('rooms')
          .setValue(this.editReservationAddOnTemplate.addOnsRelation.rooms);
      }
    }
    if (this.reservationAddOnTemplateFormGroup.value.allPrices.length == 0) {
      this.addNewUnit();
    }
  }
  get formData() {
    return <FormArray>this.reservationAddOnTemplateFormGroup.get('allPrices');
  }
  addNewUnit() {
    this.allPrices = this.reservationAddOnTemplateFormGroup.get('allPrices') as FormArray;
    this.allPrices.push(this.createUnit());
  }

  removeUnit(index: number) {
    this.allPrices = this.reservationAddOnTemplateFormGroup.get('allPrices') as FormArray;
    this.allPrices.removeAt(index);
  }
  createUnit(): FormGroup {
    return this.formBuilder.group({
      guestCountFrom: [1, Validators.required],
      guestCountTo: [1, Validators.required],
      price: ['', Validators.required]
    });
  }

  onChange(file: File) {
    if (file) {
      const fileTypes = ['image/jpeg', 'image/gif', 'image/jpg', 'image/png'];
      if (!fileTypes.includes(file.type)) {
        this.snackBar.open(this.photoFormatError, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
        return;
      }

      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      img.onload = event => {
        // const width = img.naturalWidth;
        // const height = img.naturalHeight;
        // if (width < 800 && height < 200) {
        //   this.snackBar.open(this.photoSizeError, '', {
        //     duration: 2000,
        //     panelClass: ['snackbar-error']
        //   });
        //   return;
        // }
        this.fileName = file.name;
        this.reservationAddOnTemplateFormGroup.get('file').setValue(file);
        this.imageUrl = reader.result;
      };
    }
  }

  removeFile() {
    this.fileName = null;
    this.reservationAddOnTemplateFormGroup.get('file').setValue('');
    this.reservationAddOnTemplateFormGroup.get('deletePhoto').setValue(true);
    this.imageUrl = null;
  }
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  add() {
    if (!this.reservationAddOnTemplateFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    if (
      this.reservationAddOnTemplateFormGroup.value &&
      this.reservationAddOnTemplateFormGroup.value.allPrices &&
      this.reservationAddOnTemplateFormGroup.value.allPrices.length > 0
    ) {
      this.reservationAddOnTemplateFormGroup.value.allPrices.map((item: any) => {
        item.price = item.price.replace(',', '.');
      });
    }

    const addData = this.reservationAddOnTemplateFormGroup.value;
    if (addData.rooms.length) {
      addData.rooms = addData.rooms.map((room: any) => room.id);
    }

    this.addObservable = this.reservationService
      .addReservationAddOnTemplate(addData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.reservationAddOnTemplateFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.error = null;
    if (
      this.reservationAddOnTemplateFormGroup.value &&
      this.reservationAddOnTemplateFormGroup.value.allPrices &&
      this.reservationAddOnTemplateFormGroup.value.allPrices.length > 0
    ) {
      this.reservationAddOnTemplateFormGroup.value.allPrices.map((item: any) => {
        item.price = item.price.replace(',', '.');
      });
    }

    const editData = this.reservationAddOnTemplateFormGroup.value;
    if (editData.rooms.length) {
      editData.rooms = editData.rooms.map((room: any) => room.id);
    }
    this.editObservable = this.reservationService
      .editReservationAddOnTemplate(editData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }

  delete() {
    this.deleteObservable = this.reservationService
      .deleteReservationAddOnTemplate(this.reservationAddOnTemplateFormGroup.value.id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        const snackBarRef = this.snackBar.open('Reservation Addon successfully deleted.', '', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        this.passEntry.emit('delete');
      });
  }

  addVariable(variable: string) {}

  getAllRooms() {
    this.reservationService
      .getRooms()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((rooms: any) => {
        this.rooms = rooms;
      });
  }
}
