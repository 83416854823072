import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-server-not-respond',
  templateUrl: './server-not-respond.component.html',
  styleUrls: ['./server-not-respond.component.scss']
})
export class ServerNotRespondComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
