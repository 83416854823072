import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import {
  MatStepperModule,
  MatDatepickerModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatCheckboxModule
} from '@angular/material';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ErrorPagesRoutingModule } from './error-pages.routing';
import { ShellForbiddenComponent } from './shell-forbidden/shell-forbidden.component';
import { ServerNotRespondComponent } from './server-not-respond/server-not-respond.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    ErrorPagesRoutingModule,
    SharedModule,
    MatStepperModule,
    MatDatepickerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCheckboxModule
  ],
  declarations: [NotFoundComponent, ForbiddenComponent, ShellForbiddenComponent, ServerNotRespondComponent],
  entryComponents: []
})
export class ErrorPagesModule {}
