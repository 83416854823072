import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  HostListener,
  SimpleChanges,
  OnChanges,
  AfterContentChecked,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';
import { Reservation, Page } from '../reservation.model';
import { AddReservationComponent } from '../reservations/add-reservation/add-reservation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { ReservationService } from '../reservation.service';
import moment from 'moment';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { ReservationHistoryComponent } from '../reservations/reservation-history/reservation-history.component';
import { AuthenticationService, Constants } from '@app/core';
import { Router } from '@angular/router';
import { CancelMsgBoxComponent } from '../cancel-msg-box/cancel-msg-box.component';
import { BVAddReservationComponent } from '../reservations/bv-add-reservation/bv-add-reservation.component';
import { EventsService } from '@app/events/events.service';
moment.locale('de');

@Component({
  selector: 'app-reservations-list',
  templateUrl: './reservations-list.component.html',
  styleUrls: ['./reservations-list.component.scss']
})
export class ReservationsListComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() public reservations: any = [];
  @Output() reservationsChange: EventEmitter<any> = new EventEmitter();
  @Input() public isFullscreen = false;
  @Input() public editing: any = {};
  @Input() public resetTimeFilter: any = {};
  @Input() public loading: any = {};
  @Input() public isArchiveList: boolean;
  @Input() public showSummary = true;
  @Input() public showFooter = false;
  @Input() public date: any;
  @Input() public limit = 1000;
  @Input() public hideColumns: any = [];
  @Input() public emptyMessage = 'Keine Reservierungen an diesem Tag';
  @Input() public showListOfReservation: any;
  @Input() public startDate: any;
  @Input() public endDate: any;
  @Input() public reservationListActive: boolean;
  @Input() public allStatus: any;
  @Input() public resStatusList: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() reservationsFiltered: EventEmitter<any> = new EventEmitter();
  todayDate: Date = new Date();
  bookSettings: any;
  settings: any;
  totalReservations = 0;
  totalGuests = 0;
  sortingEvent: any;
  showReservationHistory: boolean = false;
  startTime = '';
  endTime = '';
  allReservations: any = [];
  filteredReservations: any = [];
  timeList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  shiftDetails: any = [];
  @ViewChild('dataTable', { static: false }) table: any;
  loadingTable: boolean = true;
  // Layout
  readonly LAYOUT = {
    XXS: 450,
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560
  };
  layout: number = this.LAYOUT.MD;

  private ngUnsubscribe: Subject<any> = new Subject();

  fsValue = 'fs-14';
  isChecked: boolean = false;
  sortReservationList: any = [];
  showReservationId: boolean;
  showOptions: boolean = false;

  constructor(
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private reservationService: ReservationService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthenticationService,
    private router: Router,
    private eventService: EventsService
  ) {
    this.reservationService.fsSubject.subscribe((value: any) => {
      this.fsValue = ~value.indexOf('fs-') ? value : `fs-${value}`;
    });
    this.getRowClass = this.getRowClass.bind(this);
  }

  ngOnInit() {
    this.reservationService
      .getReservationBookSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.bookSettings = settings;
        this.showReservationId = this.bookSettings.showReservationId === 1 ? true : false;
      });

    this.authService
      .getClientResSettings(this.authService.activeClientId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.settings = settings;
        if (!this.settings || Object.keys(this.settings).length < 1) {
          this.router.navigate([`/client/${this.authService.activeClientId}/reservation/demo`]).then(() => {});
        }
      });

    setTimeout(() => {
      if (this.table && this.table.rowDetail) {
        // this.table.rowDetail.expandAllRows();
        // this.table.recalculate();

        this.reservations = this.table._internalRows;
        this.reservationsChange.emit(this.reservations);
      }

      this.calculateReservationCounters();
    }, 100);

    // Update current date
    setInterval(() => {
      this.todayDate = new Date();
    }, 60000);
    if (this.reservationListActive === true && this.reservations.length) {
      this.groupReservationListByDate();
    }
    this.modifiedReservationArray();
  }

  modifiedReservationArray() {
    if (this.reservations.length > 0) {
      this.reservations = this.reservations.map((item: any) => ({
        ...item,
        showValue:
          item.status !== 'pending' &&
          item.status !== 'confirmed' &&
          item.status !== 'arrived' &&
          item.status !== 'placed' &&
          item.status !== 'canceled' &&
          item.status !== 'blocked'
      }));
    }
  }

  groupReservationListByDate() {
    this.sortReservationList = [];
    // Group reservations by reservedFor date
    const groupedReservations = this.reservations.reduce((acc: any, reservation: any) => {
      const date = reservation.reservedFor.split(' ')[0];
      acc[date] = acc[date] || [];
      acc[date].push(reservation);
      return acc;
    }, {});
    // this.sortReservationList = groupedReservations;

    for (let date in groupedReservations) {
      groupedReservations[date].forEach((item: any) => {
        item.date = date; // Adding date for display
        this.sortReservationList.push(item);
      });
    }
  }

  calculateReservationCounters() {
    this.allReservations = this.reservations;
    this.reservationCountersCalculation();
    if (this.bookSettings && this.bookSettings.showTimeFilter) {
      this.filterReservationsByTime();
    } else {
      this.reservations.map((item: any) => {
        this.filteredReservations.push(item.id);
      });
      this.reservationsFiltered.emit(this.filteredReservations);
    }
  }

  reservationCountersCalculation() {
    this.totalReservations = 0;
    this.totalGuests = 0;
    let shiftDetailsArray: any = [];
    this.shiftDetails = [];
    for (let res of this.reservations) {
      if (res.status !== 'canceled') {
        this.totalReservations += 1;
        this.totalGuests += res.peopleCount;
        if (res.shiftId && this.bookSettings && this.bookSettings.includeShift > 0) {
          if (!shiftDetailsArray[res.shiftId]) {
            shiftDetailsArray[res.shiftId] = { guestCount: res.peopleCount, shiftName: res.shift && res.shift.name };
          } else {
            shiftDetailsArray[res.shiftId].guestCount += res.peopleCount;
          }
        }
      }
    }
    if (shiftDetailsArray.length > 0) {
      shiftDetailsArray.map((item: any) => {
        if (item.shiftName) {
          this.shiftDetails.push(item.guestCount + (item.guestCount === 1 ? ' Gast ' : ' Gäste ') + item.shiftName);
        }
      });
      this.shiftDetails = this.shiftDetails.join(', ');
    }
    this.loadingTable = this.loading == false ? false : true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.sortingEvent && changes.reservations && changes.loading) {
      this.onSort(this.sortingEvent);
    }

    setTimeout(() => {
      if (this.table && this.table.rowDetail) {
        this.reservations = this.table._internalRows;
        this.reservationsChange.emit(this.reservations);
      }
      this.calculateReservationCounters();
    }, 100);

    if (changes.reservations && this.reservationListActive === true) {
      this.groupReservationListByDate();
    }
    this.modifiedReservationArray();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.table) {
      setTimeout(() => {
        // this.table.rowDetail.expandAllRows();
        // this.table.recalculate();
      }, 50);
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: any) {
    if (this.table) {
      // this.table.rowDetail.expandAllRows();
      // this.table.recalculate();
    }
  }

  onRowActivate(event: any) {
    if (event.type === 'click' && event.column.prop !== 'status' && !this.showReservationHistory) {
      this.editReservation(event.row);
    }
  }

  editReservation(reservation: Reservation) {
    let appendContainer = 'body';
    if (this.isFullscreen) {
      appendContainer = '.fullscreen-element';
    }

    const modalComponent =
      this.authService.activeClientId == Constants.BVClient ? BVAddReservationComponent : AddReservationComponent;
    const modalRef = this.modalService.open(modalComponent, {
      size: 'lg',
      container: appendContainer
    });

    modalRef.componentInstance.reservation = reservation;
    modalRef.componentInstance.isWalkIn = !reservation.gastId ? true : false;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.calculateReservationCounters();
      this.snackBar.open('Reservierung erfolgreich geändert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  isSameDayHelper(date: any) {
    if (!this.date) {
      return true;
    }
    return moment(date).isSame(this.date, 'day');
  }

  getRowClass(row: any) {
    if (!this.bookSettings) {
      return;
    }
    const momentEndDate = moment(row.createdAt);
    const duration = moment.duration(moment(new Date()).diff(momentEndDate));
    return {
      'new-reservation': this.bookSettings.highlightNew && duration.asHours() < 3
    };
  }

  setIndividualStatusInfo(item: any) {
    let statusInfo: any;
    if (Constants.fixedStatusMap[item.status]) {
      statusInfo = Constants.fixedStatusMap[item.status];
    } else {
      let customStatus = this.allStatus.find((status: any) => status.value === item.status);
      if (customStatus) {
        statusInfo = customStatus;
      } else {
        statusInfo = { ...Constants.defaultStatus, label: item.status, value: item.status };
      }
    }
    let showValue =
      item.status !== 'pending' &&
      item.status !== 'confirmed' &&
      item.status !== 'arrived' &&
      item.status !== 'placed' &&
      item.status !== 'canceled' &&
      item.status !== 'blocked';

    return { ...item, statusInfo, showValue };
  }

  updateStatusHelper(
    status: string,
    cell: any,
    rowIndex: number,
    reservationId: number,
    statusChangeMsg = '',
    reservation: any
  ) {
    this.editing[rowIndex + '-' + cell] = false;
    // this.reservations[rowIndex][cell] = status;
    const index = this.reservations.findIndex((x: any) => x.id === reservationId);
    this.reservations[index].status = status;
    this.reservations[index] = this.setIndividualStatusInfo(this.reservations[index]);
    this.reservations = [...this.reservations];
    this.groupReservationListByDate();
    const reservData: any = {};
    reservData.id = this.reservations[index].id;
    reservData.clientId = this.reservations[index].betriebId;
    reservData.status = status;
    reservData.guestData = this.reservations[index].guestData;
    if (reservData.guestData) {
      reservData.guestData.guestInform = true;
    }
    reservData.page = 'list-reservation';
    reservData.statusChangeMsg = statusChangeMsg;
    reservData.isBvClient = reservData.clientId == Constants.BVClient ? true : false;
    // reservData.guestCount = reservData.peopleCount;
    this.reservationService
      .editReservation(reservData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        () => {
          if (status == 'confirmed' || status == 'canceled') {
            this.updateReservation(status.toUpperCase(), reservation);
          }
          if (status == 'canceled' && reservation.ticketOrderId) {
            this.cancelTicketOrder(reservation.ticketOrderId);
          }
          this.snackBar.open('Status wurde erfolgreich geändert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          this.snackBar.open(`Status konnte nicht geändert werden (${err.error.msg})`, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  updateStatus(status: string, cell: any, rowIndex: number, reservation: any) {
    let reservationId = reservation.id;
    if (this.bookSettings.confirmStatusChange) {
      let appendContainer = 'body';
      if (this.isFullscreen) {
        appendContainer = '.fullscreen-element';
      }

      const modalRef = this.modalService.open(ConfirmModalComponent, {
        size: 'lg',
        container: appendContainer
      });
      modalRef.componentInstance.title = 'Status ändern';
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Status auf "${this.translateStatus(
        status
      )}" setzen möchten?`;
      modalRef.componentInstance.showInfo = false;
      modalRef.componentInstance.buttonText = 'Ja';

      modalRef.result.then(
        (result: any) => {
          if (result === 'ok') {
            this.callUpdateStatus(status, cell, rowIndex, reservationId, reservation);
            // if (status == 'confirmed' || status == 'canceled') {
            //   this.updateReservation(status.toUpperCase(), reservation);
            // }
          }
        },
        () => {}
      );
    } else {
      this.callUpdateStatus(status, cell, rowIndex, reservationId, reservation);
      // if (status == 'confirmed' || status == 'canceled') {
      //   this.updateReservation(status.toUpperCase(), reservation);
      // }
    }

    this.calculateReservationCounters();
  }

  translateStatus(status: string) {
    switch (status) {
      case 'confirmed':
        return 'Bestätigt';
        break;
      case 'canceled':
        return 'Storniert';
        break;
      case 'noShow':
        return 'No Show';
        break;
      case 'arrived':
        return 'Angekommen';
        break;
      case 'placed':
        return 'Platziert';
        break;
      case 'pending':
        return 'Ausstehend';
        break;
      case 'waiting':
        return 'Warteliste';
        break;
      case 'finished':
        return 'Fertig';
        break;
      case 'blocked':
        return 'Blocked';
        break;
      default:
        return status;
        break;
    }
  }

  isColumnHidden(columnName: string) {
    return this.hideColumns.includes(columnName);
  }

  onSort(event: any) {
    this.sortingEvent = event;
    // event was triggered, start sort sequence
    this.loading = true;
    // emulate a server request with a timeout
    setTimeout(() => {
      const rows = [...this.reservations];
      const sort = event.sorts[0];

      if (event.column.prop === 'tables') {
        rows.sort((a, b) => {
          if (!a.tables.length) {
            return 0;
          }
          if (!b.tables.length) {
            return -1;
          }
          return a.tables[0].name.localeCompare(b.tables[0].name) * (sort.dir === 'desc' ? -1 : 1);
        });
      } else {
        rows.sort((a, b) => {
          let aValue = a[sort.prop]
            ? a[sort.prop]
            : a[sort.prop.split('.')[0]]
            ? a[sort.prop.split('.')[0]][sort.prop.split('.')[1]]
            : '';
          let bValue = b[sort.prop]
            ? b[sort.prop]
            : b[sort.prop.split('.')[0]]
            ? b[sort.prop.split('.')[0]][sort.prop.split('.')[1]]
            : '';
          return String(aValue).localeCompare(String(bValue)) * (sort.dir === 'desc' ? -1 : 1);
        });
      }

      this.reservations = rows;
      this.reservationsChange.emit(this.reservations);
      this.loading = false;
    }, 500);
  }

  updateReservation(status: string, reservation: any) {
    const referrer = reservation.referrer ? reservation.referrer : '';
    if (referrer && referrer.includes('Reservierung mit Google')) {
      this.reservationService
        .getupdateGoogleReseration(status, reservation)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((settings: any) => {
          if (settings) {
            this.snackBar.open(`Reservation ${status} From Google`, '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
        });
    }
  }

  cancelTicketOrder(ticketOrderId: any) {
    this.eventService.cancelOrder(ticketOrderId).subscribe(
      () => {
        console.log('Ticket Order canceled');
      },
      err => {
        this.snackBar.open(err.error.msg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  getGuestReservationHistory(guestData: any) {
    this.showReservationHistory = true;

    let appendContainer = 'body';
    if (this.isFullscreen) {
      appendContainer = '.fullscreen-element';
    }

    const modalRef = this.modalService.open(ReservationHistoryComponent, {
      size: 'lg',
      container: appendContainer
    });

    modalRef.componentInstance.guestData = guestData;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.showReservationHistory = false;
      modalRef.close();
    });
  }

  callResetTimeFilter() {
    this.startTime = '';
    this.endTime = '';
    if (this.bookSettings && this.bookSettings.showTimeFilter) {
      this.filterReservationsByTime();
    }
  }
  filterReservationsByTime() {
    if (this.resetTimeFilter) {
      this.startTime = '';
      this.endTime = '';
    }
    this.filteredReservations = [];
    this.resetTimeFilter = false;
    // const startDate = moment.isMoment(this.date) ? moment(this.date).toDate() : this.date;
    // const endDate = moment.isMoment(this.date) ? moment(this.date).toDate() : this.date;
    let startDate: Date;
    let endDate: Date;
    if (this.startDate && this.endDate) {
      startDate = new Date(this.startDate);
      endDate = new Date(this.endDate);
    } else {
      startDate = new Date(this.date);
      endDate = new Date(this.date);
    }
    setTimeout(() => {
      if (this.startTime && this.startTime.length > 0) {
        startDate.setHours(parseInt(this.startTime), 0, 0, 0);
      } else {
        startDate.setHours(0, 0, 0, 0);
      }
      if (this.endTime && this.endTime.length > 0) {
        endDate.setHours(parseInt(this.endTime), 0, 0, 0);
      } else {
        endDate.setHours(0, 0, 0, 0);
      }

      this.reservations = this.allReservations.filter((item: any) => {
        if (this.endTime) {
          if (new Date(item.reservedFor) >= startDate && new Date(item.reservedFor) <= endDate) {
            return item;
          }
        } else {
          if (new Date(item.reservedFor) >= startDate) {
            return item;
          }
        }
      });
      this.changeDetector.detectChanges();
      if (this.reservations) {
        this.reservations.map((item: any) => {
          this.filteredReservations.push(item.id);
        });
        this.reservationCountersCalculation();
        this.reservationsFiltered.emit(this.filteredReservations);
      }
    }, 800);
  }

  callUpdateStatus(status: string, cell: any, rowIndex: number, reservationId: number, reservation: any) {
    if (status == 'canceled' && this.settings.remindGuests && this.settings.remindGuestsEmail) {
      let cancelMsg = '';
      if (reservation.status === 'pending' && status === 'canceled') {
        cancelMsg = this.settings.declineText;
      }
      if (reservation.status === 'confirmed' && status === 'canceled') {
        cancelMsg = this.settings.cancelText;
      }
      const modalRef = this.modalService.open(CancelMsgBoxComponent, { windowClass: 'onboarding-modal' });
      modalRef.componentInstance.msg = cancelMsg;
      modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        this.updateStatusHelper(status, cell, rowIndex, reservationId, receivedEntry.msg, reservation);
      });
    } else {
      this.updateStatusHelper(status, cell, rowIndex, reservationId, '', reservation);
    }
  }

  onValChange(value: any) {
    console.log('value ==>', value.checked);
    this.isChecked = value.checked;
  }
}
